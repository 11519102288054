var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"persistent":"","width":"unset"},model:{value:(_vm.cod_articolo_dialog),callback:function ($$v) {_vm.cod_articolo_dialog=$$v},expression:"cod_articolo_dialog"}},[_c('v-overlay',{staticStyle:{"z-index":"10"},attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',[_vm._v(" Nuova Commessa Multipla")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ml-3",attrs:{"small":""},on:{"click":_vm.goBack}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_c('span',[_vm._v("Indietro")])])],1),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"align":"center","cols":"4"}},[_vm._v(" Inserisci il codice articolo per la nuova commessa: ")]),_c('v-col',{attrs:{"align":"center","cols":"4"}},[_c('v-text-field',{attrs:{"label":"Cod. Articolo","placeholder":"Cod. Articolo"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchResult()}},model:{value:(_vm.cod_articolo_sel),callback:function ($$v) {_vm.cod_articolo_sel=$$v},expression:"cod_articolo_sel"}})],1),_c('v-col',{attrs:{"align":"left","cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.goToArticolo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("Vai all'Articolo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.searchResult}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Cerca")])])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"artDialog","scrollable":false,"persistent":"","width":"unset"},model:{value:(_vm.articolo_search_results),callback:function ($$v) {_vm.articolo_search_results=$$v},expression:"articolo_search_results"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Articolo ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){_vm.articolo_search_results = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-window-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-form',[_c('v-container',[_c('v-row',{staticClass:"mx-auto ml-auto",attrs:{"align":"center"}},[_c('v-col',{attrs:{"align":"center","cols":"4"}},[_c('v-text-field',{attrs:{"label":"Cod. Articolo","placeholder":"Cod. Articolo"},model:{value:(_vm.cod_articolo_sel),callback:function ($$v) {_vm.cod_articolo_sel=$$v},expression:"cod_articolo_sel"}})],1),_c('v-col',{attrs:{"align":"left","cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"small":""},on:{"click":_vm.goToArticolo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("Vai all'Articolo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.searchResult}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Cerca")])])],1),_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"","sm":"4","xs":"12","md":"3"}})],1)],1)],1),_c('v-card-text',{staticStyle:{"max-height":"600px","overflow-y":"auto"}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{attrs:{"footer-props":{ 'items-per-page-options': [100, -1] },"headers":_vm.articoli_headers,"items":_vm.articoli_items},scopedSlots:_vm._u([{key:"item.dettaglio",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.clickDettaglio(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"70%"},model:{value:(_vm.comm_multipla_dialog),callback:function ($$v) {_vm.comm_multipla_dialog=$$v},expression:"comm_multipla_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Dettagli Commessa Multipla ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Chiudi")])])],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"mr-5",staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Qtà Commessa","type":"number"},model:{value:(_vm.qta_commessa),callback:function ($$v) {_vm.qta_commessa=$$v},expression:"qta_commessa"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mr-5",staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Data Commessa","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.data_commessa = ''}}},on),[_vm._v("mdi-calendar-month")])]},proxy:true}],null,true),model:{value:(_vm.data_commessa),callback:function ($$v) {_vm.data_commessa=$$v},expression:"data_commessa"}},on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.data_commessa),callback:function ($$v) {_vm.data_commessa=$$v},expression:"data_commessa"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"289","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mr-5",staticStyle:{"display":"inline-block","max-width":"350px"},attrs:{"background-color":"#ffffff","dense":"","hide-details":"","label":"Data Consegna","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.data_consegna = ''}}},on),[_vm._v("mdi-calendar-month")])]},proxy:true}],null,true),model:{value:(_vm.data_consegna),callback:function ($$v) {_vm.data_consegna=$$v},expression:"data_consegna"}},on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.data_consegna),callback:function ($$v) {_vm.data_consegna=$$v},expression:"data_consegna"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":_vm.valida}},[_vm._v("GENERA")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.comm_multipla_headers,"items":_vm.comm_multiple},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.cod_articolo,class:item.nr_fasi == 0 ? 'custom-highlight-row' : ''},[_c('td',{staticStyle:{"text-align":"left"}},[(item.nr_fasi > 0)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.switchGenera(item)}}},[_c('v-icon',{attrs:{"color":item.chkGenera == 1 ? 'success' : 'error'}},[_vm._v(_vm._s(item.chkGenera == 1 ? "mdi-check" : "mdi-close"))])],1):_vm._e()],1),_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(item.cod_articolo)+" ")]),_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(item.des_articolo)+" ")]),_c('td',[_c('v-btn',{staticClass:"my-3",attrs:{"color":item.id_disegno == null ? 'grey lighten-5' : 'primary'},on:{"click":function($event){return _vm.clickVisualizzaDisegno(item)}}},[_c('v-icon',{attrs:{"color":item.id_disegno == null ? 'black' : 'white'}},[_vm._v(" mdi-eye ")])],1)],1),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.livello)+" ")]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.quantita)+" ")]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.qtaTotale)+" ")]),_c('td',{staticStyle:{"text-align":"center"}},[(item.nr_fasi > 0)?_c('v-btn',{attrs:{"tile":"","x-small":""},on:{"click":function($event){item.qtaTotale_somma = item.qtaTotale_somma - 1;
                      _vm.ricalcolaQuantita(item);}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-minus")])],1):_vm._e(),(item.nr_fasi > 0)?_c('v-btn',{attrs:{"tile":"","x-small":""},on:{"click":function($event){item.qtaTotale_somma = item.qtaTotale_somma + 1;
                      _vm.ricalcolaQuantita(item);}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.nr_fasi)+" ")])])}),0)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.conferma_dialog),callback:function ($$v) {_vm.conferma_dialog=$$v},expression:"conferma_dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Conferma Generazione ")])],1),_c('v-card-text',{staticStyle:{"font-size":"20px","margin-top":"25px"}},[_vm._v(" Sei sicuro di voler generare una commessa con work order multiplo per l'articolo "),_c('b',[_vm._v(_vm._s(_vm.cod_articolo_sel))]),_vm._v("? ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6","align":"right"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.conferma_dialog = false}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)],1),_c('v-col',{attrs:{"cols":"6","align":"left"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.setCommessaMultipla();
              _vm.conferma_dialog = false;}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.visualizza_disegno_visible),callback:function ($$v) {_vm.visualizza_disegno_visible=$$v},expression:"visualizza_disegno_visible"}},[_c('VisualizzaDisegno',{attrs:{"visible":_vm.visualizza_disegno_visible,"work_order_sel":_vm.work_order_sel},on:{"click_chiudi":function($event){_vm.visualizza_disegno_visible = !_vm.visualizza_disegno_visible}}})],1),_c('v-snackbar',{attrs:{"content-class":"snackbar","color":_vm.snackbar_background,"right":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color,"size":64}},[_vm._v(_vm._s(_vm.snackbar_icon))]),_c('h2',{staticClass:"ml-16",style:({color:_vm.snackbar_text_color+'!important'})},[_vm._v(_vm._s(_vm.snackbar_text))]),_c('v-btn',{staticStyle:{"margin-top":"-6rem","float":"right !important","margin-right":"-1rem !important"},attrs:{"fab":"","dark":"","text":"","small":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',{attrs:{"color":_vm.snackbar_icon_color}},[_vm._v("mdi-window-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }