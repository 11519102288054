<template>
  <v-container>
    <v-dialog persistent v-model="cod_articolo_dialog" width="unset">
      <v-overlay :value="overlay" style="z-index: 10">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-toolbar class="white--text" color="indigo" dense>
          <v-toolbar-title> Nuova Commessa Multipla</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-3" @click="goBack" small>
                <v-icon v-on="on" v-bind="attrs" color="error"
                  >mdi-keyboard-backspace</v-icon
                >
              </v-btn>
            </template>
            <span>Indietro</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center" align="center">
            <v-col align="center" cols="4">
              Inserisci il codice articolo per la nuova commessa:
            </v-col>
            <v-col align="center" cols="4">
              <v-text-field
              v-on:keyup.enter="searchResult()"
                v-model="cod_articolo_sel"
                label="Cod. Articolo"
                placeholder="Cod. Articolo"
              ></v-text-field>
            </v-col>
            <v-col align="left" cols="4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    class="mr-5"
                    @click="goToArticolo"
                    small
                  >
                    <v-icon color="success">mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>Vai all'Articolo</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" @click="searchResult" small>
                    <v-icon color="primary">mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <span>Cerca</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="articolo_search_results"
      content-class="artDialog"
      :scrollable="false"
      persistent
      width="unset"
    >
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text"> Articolo </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="articolo_search_results = false"
                small
              >
                <v-icon color="error"> mdi-window-close</v-icon></v-btn
              >
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-form>
          <v-container>
            <v-row class="mx-auto ml-auto" align="center">
              <v-col align="center" cols="4">
                <v-text-field
                  v-model="cod_articolo_sel"
                  label="Cod. Articolo"
                  placeholder="Cod. Articolo"
                ></v-text-field>
              </v-col>
              <v-col align="left" cols="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="mr-5"
                      @click="goToArticolo"
                      small
                    >
                      <v-icon color="success">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Vai all'Articolo</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" @click="searchResult" small>
                      <v-icon color="primary">mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                  <span>Cerca</span>
                </v-tooltip>
              </v-col>
              <v-col class="mr-auto" cols sm="4" xs="12" md="3"> </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card-text style="max-height: 600px; overflow-y: auto">
          <v-row>
            <v-col md="12">
              <v-data-table
                :footer-props="{ 'items-per-page-options': [100, -1] }"
                :headers="articoli_headers"
                :items="articoli_items"
              >
                <template v-slot:[`item.dettaglio`]="{ item }">
                  <v-btn @click="clickDettaglio(item)" small>
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="comm_multipla_dialog" width="70%">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Dettagli Commessa Multipla
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small @click="goBack">
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Chiudi</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-row align="center">
            <v-col cols="3">
              <v-text-field
                background-color="#ffffff"
                class="mr-5"
                dense
                hide-details
                label="Qtà Commessa"
                style="display: inline-block; max-width: 350px"
                type="number"
                v-model="qta_commessa"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="289"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#ffffff"
                    class="mr-5"
                    dense
                    hide-details
                    label="Data Commessa"
                    style="display: inline-block; max-width: 350px"
                    v-model="data_commessa"
                    readonly
                    v-on="on"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="data_commessa = ''"
                        color="primary"
                        v-on="on"
                        >mdi-calendar-month</v-icon
                      >
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="data_commessa"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="289"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#ffffff"
                    class="mr-5"
                    dense
                    hide-details
                    label="Data Consegna"
                    style="display: inline-block; max-width: 350px"
                    v-model="data_consegna"
                    readonly
                    v-on="on"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="ml-2"
                        @click="data_consegna = ''"
                        color="primary"
                        v-on="on"
                        >mdi-calendar-month</v-icon
                      >
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="data_consegna"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-btn @click="valida" color="success" dark>GENERA</v-btn>
            </v-col>
          </v-row>
          <v-data-table :headers="comm_multipla_headers" :items="comm_multiple">
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  :class="item.nr_fasi == 0 ? 'custom-highlight-row' : ''"
                  v-for="item in items"
                  :key="item.cod_articolo"
                >
                  <td style="text-align: left">
                    <v-btn
                      @click="switchGenera(item)"
                      small
                      v-if="item.nr_fasi > 0"
                    >
                      <v-icon
                        :color="item.chkGenera == 1 ? 'success' : 'error'"
                        >{{
                          item.chkGenera == 1 ? "mdi-check" : "mdi-close"
                        }}</v-icon
                      >
                    </v-btn>
                  </td>
                  <td style="text-align: left">
                    {{ item.cod_articolo }}
                  </td>
                  <td style="text-align: left">
                    {{ item.des_articolo }}
                  </td>
                  <td>
                    <v-btn
                      class="my-3"
                      :color="
                        item.id_disegno == null ? 'grey lighten-5' : 'primary'
                      "
                      @click="clickVisualizzaDisegno(item)"
                    >
                      <v-icon
                        :color="item.id_disegno == null ? 'black' : 'white'"
                      >
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </td>
                  <td style="text-align: center">
                    {{ item.livello }}
                  </td>
                  <td style="text-align: center">
                    {{ item.quantita }}
                  </td>
                  <td style="text-align: center">
                    {{ item.qtaTotale }}
                  </td>
                  <td style="text-align: center">
                    <v-btn
                      @click="
                        item.qtaTotale_somma = item.qtaTotale_somma - 1;
                        ricalcolaQuantita(item);
                      "
                      tile
                      v-if="item.nr_fasi > 0"
                      x-small
                      ><v-icon color="primary">mdi-minus</v-icon></v-btn
                    >
                    <v-btn
                      @click="
                        item.qtaTotale_somma = item.qtaTotale_somma + 1;
                        ricalcolaQuantita(item);
                      "
                      tile
                      v-if="item.nr_fasi > 0"
                      x-small
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-btn>
                  </td>
                  <td style="text-align: center">
                    {{ item.nr_fasi }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="conferma_dialog" width="unset">
      <v-card>
        <v-toolbar color="indigo" dense>
          <v-toolbar-title class="white--text">
            Conferma Generazione
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text style="font-size: 20px; margin-top: 25px">
          Sei sicuro di voler generare una commessa con work order multiplo per
          l'articolo <b>{{ cod_articolo_sel }}</b
          >?
        </v-card-text>
        <v-row justify="center">
          <v-col cols="6" align="right">
            <v-btn @click="conferma_dialog = false" small>
              <v-icon color="error">mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" align="left">
            <v-btn
              @click="
                setCommessaMultipla();
                conferma_dialog = false;
              "
              small
            >
              <v-icon color="success"> mdi-check </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="visualizza_disegno_visible">
      <VisualizzaDisegno
        @click_chiudi="visualizza_disegno_visible = !visualizza_disegno_visible"
        :visible="visualizza_disegno_visible"
        :work_order_sel="work_order_sel"
      />
    </v-dialog>
    <v-snackbar
      content-class="snackbar"
      :color="snackbar_background"
      right
      v-model="snackbar"
      :timeout="3000"
    >
      <v-icon :color="snackbar_icon_color" :size="64">{{ snackbar_icon }}</v-icon>
      <h2 :style="{color:snackbar_text_color+'!important'}" class="ml-16">{{ snackbar_text }}</h2>

      <v-btn
        @click="snackbar = false"
        fab
        dark
        text
        small
        style="
          margin-top: -6rem;
          float: right !important;
          margin-right: -1rem !important;
        "
      >
        <v-icon :color="snackbar_icon_color">mdi-window-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import VisualizzaDisegno from "@/components/PRODUCTION/ProduzioneOnline/VisualizzaDisegno.vue";
import moment from "moment/moment";

export default {
  name: "NuovaComessaMultipla",
  components: { VisualizzaDisegno },
  data() {
    return {
      // Variabili d'appoggio
      cod_articolo_sel: null,
      cod_commessa_sel: null,
      data_commessa: "",
      data_consegna: "",
      des_commessa_sel: null,
      id_articolo_sel: null,
      id_famiglia_sel: null,
      id_tipo_fornitura_sel: null,
      id_tipo_sel: null,
      indice_disegno_sel: null,
      qta_commessa: 1,
      work_order_sel: null,

      // Modal
      articolo_search_results: false,
      cod_articolo_dialog: false,
      comm_multipla_dialog: false,
      conferma_dialog: false,
      menu1: null,
      menu2: null,
      overlay: false,
      snackbar: false,
      snackbar_background: "",
      snackbar_icon: "",
      snackbar_icon_color: "",
      snackbar_text: "",
      snackbar_text_color: "",
      visualizza_disegno_visible: false,

      // Array
      articoli_headers: [
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Des. Articolo",
          sortable: false,
          value: "des_articolo",
        },
        {
          text: "Fam. Articolo",
          sortable: false,
          value: "fam_articolo",
        },
        {
          text: "Dettaglio",
          sortable: false,
          value: "dettaglio",
        },
      ],
      articoli_items: [],
      comm_multipla_headers: [
        {
          text: "Genera",
          sortable: "false",
          value: "chkGenera",
        },
        {
          text: "Cod. Articolo",
          sortable: false,
          value: "cod_articolo",
        },
        {
          text: "Des. Articolo",
          sortable: false,
          value: "des_articolo",
        },
        {
          text: "Disegno",
          sortable: false,
          value: "disegno",
        },
        {
          text: "Livello Distinta",
          sortable: false,
          value: "livello",
          align: "center",
        },
        {
          text: "Quantità",
          sortable: false,
          value: "quantita",
          align: "center",
        },
        {
          text: "Quantità Tot.",
          sortable: false,
          value: "qtaTotale",
          align: "center",
        },
        {
          text: "Gestisci Qtà",
          sortable: false,
          value: "gestisci",
          align: "center",
        },
        {
          text: "N° Fasi",
          sortable: false,
          value: "nr_fasi",
          align: "center",
        },
      ],
      comm_multiple: [],
    };
  },
  methods: {
    printlog() {
      console.log("ciao");
    },
    goBack() {
      this.$router.push(`/home/production/commesa`);
    },
    clickDettaglio(item) {
      this.id_articolo_sel = item.id_articolo;
      this.cod_articolo_sel = item.cod_articolo;

      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getcommessamultipla",
          token: localStorage.getItem("user_token"),
          id_articolo: this.id_articolo_sel,
        },
      };

      this.$store.dispatch("api", request).then((res) => {
        this.comm_multiple = [];
        res.data.forEach((elem) => {
          /* Creo dei campi qtaTotale_originale e qtaTotale_somma che mi
           * torneranno utili in seguito per calcolare la quantità di
           * oggetti da produrre. */
          elem.qtaTotale_originale = elem.qtaTotale;
          elem.qtaTotale_somma = 0;

          // Aggiungo l'elemento al mio array.
          this.comm_multiple.push(elem);
        });
      });
      this.articolo_search_results = false;
      this.comm_multipla_dialog = true;
    },
    clickVisualizzaDisegno(item) {
      /* Controllo che ci siano disegni da
       * visualizzare (me ne accorgo perché se
       * non ci sono id_disegno == null). */
      if (item.id_disegno == null) {
        // Se non ci sono disegni lancio una snackber di errore
        this.snackbar = true;
        this.snackbar_background = "error";
        this.snackbar_icon = "mdi-close";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Non ci sono disegni da visualizzare.";
        this.snackbar_text_color = "white";
      } else {
        console.log(JSON.stringify(item));
        this.work_order_sel = Object.assign({}, item);
        this.visualizza_disegno_visible = true;
      }
    },
    goToArticolo() {
      if (this.cod_articolo_sel === "") {
        return;
      }

      this.overlay = true;
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",

          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo_sel,
          id_famiglia:
            this.id_famiglia_sel == null ? "" : this.id_famiglia.id_tabella,
          id_tipo: this.id_tipo == null ? "" : this.id_tipo.id_tabella,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.overlay = false;

        if (res.data.lista.length > 1) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text = "Sono presenti più articoli con questo codice.";
          this.snackbar_text_color = "white";
        }

        if (res.data.lista.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "error";
          this.snackbar_icon = "mdi-close";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Non sono stati trovati articoli con questo codice.";
          this.snackbar_text_color = "white";
        }

        if (res.data.lista.length === 1) {
          // console.log(this.completeData);
          this.clickDettaglio(res.data.lista[0]);
        }
      });

      this.articolo_search_results = false;
    },
    ricalcolaQuantita(item) {
      /*if (this.qta_commessa <= 0) {
        this.qta_commessa = 1;
      }*/

      item.qtaTotale =
        item.qtaTotale_originale * this.qta_commessa + item.qtaTotale_somma;

      // Se la qtaTotale è 0, disabilito il flag di generazione della commessa.
      if (item.qtaTotale == 0) {
        item.chkGenera = 0;
      }
    },
    searchResult() {
      if (this.cod_articolo_sel === "" || this.cod_articolo_sel === null) {
        return;
      }

      this.overlay = true;
      let request = {
        controller: "Magazzino",
        method: "POST",
        richiesta: {
          action: "getarticoli",

          token: localStorage.getItem("user_token"),
          cod_articolo: this.cod_articolo_sel,
          id_famiglia:
            this.id_famiglia_sel == null ? "" : this.id_famiglia.id_tabella,
          id_tipo: this.id_tipo == null ? "" : this.id_tipo.id_tabella,
        },
      };
      this.$store.dispatch("api", request).then((res) => {
        this.overlay = false;

        if (res.data.lista.length > 1) {
          this.articoli_items = res.data.lista;
          this.articolo_search_results = true;
        }

        if (res.data.lista.length === 0) {
          this.snackbar = true;
          this.snackbar_background = "orange";
          this.snackbar_icon = "mdi-alert";
          this.snackbar_icon_color = "white";
          this.snackbar_text =
            "Non sono stati trovati articoli con questo codice.";
          this.snackbar_text_color = "white";
        }

        if (res.data.lista.length === 1) {
          this.cod_articolo_sel = res.data.lista[0].cod_articolo;
          
          this.snackbar = true;
          this.snackbar_background = "success";
          this.snackbar_icon = "mdi-check";
          this.snackbar_icon_color = "white";
          this.snackbar_text = `È presente un articolo con questo codice: ${res.data.lista[0].cod_articolo}.`;
          this.snackbar_text_color = "white";
        }
      });

      this.articolo_search_results = false;
    },
    setCommessaMultipla() {
      /* Se i campi essenziali sono valorizzati
       * invio la request al BE. */
      let request = {
        controller: "Magazzino",
        method: "PUT",
        richiesta: {
          action: "setcommessamultipla",
          token: localStorage.user_token,

          cod_articolo: this.cod_articolo_sel,
          data_commessa: this.data_commessa,
          data_consegna: this.data_consegna,
          tabella: this.comm_multiple,
        },
      };

      this.overlay = true;
      //console.log(JSON.stringify(request));
      this.$store.dispatch("api", request).then(() => {
        this.overlay = false;

        this.snackbar = true;
        this.snackbar_background = "success";
        this.snackbar_icon = "mdi-check";
        this.snackbar_icon_color = "white";
        this.snackbar_text = "Work order aggiunto correttamente.";
        this.snackbar_text_color = "white";

        let that = this;
        setTimeout(function () {
          that.$router.push("/home/production/commesa");
        }, 3000);

        this.snackbar = true;
      });
    },
    switchGenera(arg) {
      if (arg.chkGenera == 1) {
        arg.chkGenera = 0;
      } else if (arg.chkGenera == 0 && arg.qtaTotale > 0) {
        /* Per cambiare chkGenera da 0 a 1 controllo che
         * prima fosse 0 e che la qtaTotale sia maggiore di 0
         * perché non posso generare work order di componenti
         * in quantità nulla.*/
        arg.chkGenera = 1;
      }
    },
    valida() {
      if (
        this.qta_commessa >= 1 &&
        this.data_commessa != "" &&
        this.data_consegna != ""
      ) {
        this.conferma_dialog = true;
      } else {
        /* Se i campi esssenziali non sono valorizzati
         * stampo un snackbar di errore. */
        // Se non ci sono disegni lancio una snackber di errore
        this.snackbar = true;
        this.snackbar_background = "orange";
        this.snackbar_icon = "mdi-alert";
        this.snackbar_icon_color = "white";
        this.snackbar_text =
          "La Q.tà Commessa (pari almeno a 1), la Data Commessa e la Data Consegna devono essere valorizzate.";
        this.snackbar_text_color = "white";
      }
    },
  },
  mounted() {
    if (this.$route.params.id == -1) {
      // Faccio omparire il dialog di creazione della commessa multipla
      this.cod_articolo_dialog = true;

      // Prendo la data di oggi e la uso per valorizzare di default la data_commessa
      let adesso = moment();
      let adesso_str = `${adesso.year()}-${parseInt(adesso.month()) + 1}-${adesso.date()}`;
      this.data_commessa = adesso_str;
    }
  },
  watch: {
    data_commessa: {
      immediate: true,
      handler() {},
    },
    data_consegna: {
      immediate: true,
      handler() {},
    },
    qta_commessa: {
      immediate: true,
      handler() {
        /* Se mi trovo nel dialog di definizione della commessa
         * multipla, moltiplico il valore di qta_commessa per
         * l'ammontare della qta_totale di pezzi per ogni fase. */
        if (this.comm_multipla_dialog == true) {
          this.comm_multiple.forEach((elem) => {
            this.ricalcolaQuantita(elem);
          });
        }
      },
    },
  },
};
</script>
<style>
.custom-highlight-row {
  background-color: pink;
}
</style>
